import React from 'react';
import { graphql } from 'gatsby';
import {
  Heading, SubHeading, Panel, Container, FlexLayout, Rel,
} from '../../elements';
import Back from '../../components/Back';
import AnimatedSvg from '../../components/AnimatedSvg';
import Card from '../../components/Card';
import IconList from '../../components/IconList';
import AppContainer from '../../components/AppContainer';
import PreviewCompatibleImage from '../../components/PreviewCompatibleImage';

const Epaper = ({ data: { contentfulPage: page } }) => {
  console.log(page);

  return (
    <AppContainer pageTitle={page.title}>
      <Container id="content">
        <Panel>
          <Back to="/" />
          <Heading ariaRole="heading">{page.heading}</Heading>
          <SubHeading>{page.subHeading}</SubHeading>
          {page.sections
            && page.sections.map(section => (
              <React.Fragment key={section.id}>
                <IconList list={section.list} />
                <FlexLayout>
                  {section.items
                    && section.items.map((item, i) => (
                      <Card key={i} item={item} component={AnimatedSvg} />
                    ))}
                </FlexLayout>
              </React.Fragment>
            ))}
        </Panel>
        <Rel style={{ minHeight: 400 }}>
          <PreviewCompatibleImage
            imgStyle={{ objectPosition: 'left top' }}
            cover
            imageInfo={page.image}
          />
        </Rel>
      </Container>
    </AppContainer>
  );
};

export const epaperPageQuery = graphql`
  query EpaperPageQuery($id: String!) {
    contentfulPage(id: { eq: $id }) {
      title
      slug
      heading
      image {
        fluid(maxWidth: 1200) {
          ...GatsbyContentfulFluid
        }
      }
      sections {
        id
        heading
        subHeading
        list {
          title
          listItem {
            text {
              text
            }
            icon
          }
        }
        items {
          label
          infoList
          extraInfo
          component
        }
      }
    }
  }
`;

export default Epaper;
